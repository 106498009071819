import { ReactElement, createElement } from "react";
import {
  AnalysisResult as Sl1Result,
  ConvertResult as ConvertSl1Result,
  ConvertResultType,
} from "../../engines/sl1Engine";
import {
  AnalysisEngineType,
  ResultDiagramIndex,
  ResultDiagramParam,
} from "../../types";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  Progress,
  Text,
  VStack,
  Image,
  useTheme,
} from "@chakra-ui/react";
import { RenderUixTable } from "./ResultUIX";
import sl1Image from "../../assets/images/sl1/sl1.png";

function roundAndAdjust(values: number[]): number[] {
  // 各数値を100倍して四捨五入し、インデックスと共に保持
  const roundedValues = values.map((val, index) => ({
    val: Math.round(val * 100),
    index,
  }));
  let total = roundedValues.reduce((sum, item) => sum + item.val, 0);

  // 値に基づいて昇順にソート
  roundedValues.sort((a, b) => a.val - b.val);

  // 合計が100になるまで調整
  while (total !== 100) {
    if (total > 100) {
      // 合計が100より大きい場合、小さい値から順に1を引く
      for (const item of roundedValues) {
        if (item.val > 0) {
          item.val -= 1;
          total -= 1;
          if (total === 100) break;
        }
      }
    } else {
      // 合計が100未満の場合、大きい値から順に1を加える
      for (let i = roundedValues.length - 1; i >= 0; i--) {
        roundedValues[i].val += 1;
        total += 1;
        if (total === 100) break;
      }
    }
  }

  // 元のインデックス順に並び替えて値のみを取り出す
  return roundedValues
    .sort((a, b) => a.index - b.index)
    .map((item) => item.val);
}

function RenderMainResult({
  imgSrc,
  content,
}: {
  imgSrc: string;
  content: ReactElement;
}): ReactElement {
  const theme = useTheme();
  const borderColor = theme.colors.primary["theme_lv1"];
  return (
    <Flex
      direction="row"
      borderTop={`2px solid ${borderColor}`}
      borderBottom={`2px solid ${borderColor}`}
      bg="white"
      padding={2}
      height="150px"
    >
      <Box width="70px" mt={2} mr={2}>
        <Image src={imgSrc} alt="image" objectFit="cover" />
      </Box>
      <Flex direction="column" width="100%">
        {content}
      </Flex>
    </Flex>
  );
}

function SeriousnessCategory({
  left,
  text,
  category,
  decision,
}: {
  left: string;
  text: string;
  category: string;
  decision: string;
}): ReactElement {
  return (
    <Text
      position="absolute"
      transform="translateX(-50%)"
      left={left}
      color={category === decision ? "primary.theme_lv1" : "gray.300"}
    >
      {text}
    </Text>
  );
}

function RenderMainContent({
  result,
}: {
  result: ConvertResultType;
}): ReactElement {
  const { t } = useTranslation();
  const progressMax = 100;
  const sas_score = result["Sl1_score"];
  const pred_ahi = result["Pred_ahi"];
  const decision =
    typeof result["Decision"] === "string" ? result["Decision"] : "-";
  const theme = useTheme();
  const progressColor = theme.colors.primary["theme_lv1"];
  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize={{ base: "sm", sm: "xl" }}>
          {t("Result.SAS_Analysis_Result")}
        </Text>
        <Text fontSize={{ base: "sm", sm: "lg" }}>
          SAS
          <Text as="sup" position="relative" top="-0.3em">
            *1
          </Text>
          <Text as="span" marginRight="0.5em">
            {t("Result.SAS_Score")}
          </Text>
          {sas_score}
        </Text>
      </Flex>
      <Flex mt={1} justifyContent="space-between">
        <Text mt="16px" whiteSpace="nowrap" fontSize="sm">
          {t("Result.SAS_Seriousness")}
        </Text>
        <VStack
          align="stretch"
          width="85%"
          height="70px"
          style={{ position: "relative" }}
        >
          {/* 目盛 */}
          <Flex position="relative" mb="3">
            <Text
              fontSize="xs"
              position="absolute"
              left="0%"
              transform="translateX(-50%)"
            >
              0
            </Text>
            <Text
              fontSize="xs"
              position="absolute"
              left="16%"
              transform="translateX(-50%)"
            >
              16
            </Text>
            <Text
              fontSize="xs"
              position="absolute"
              left="50%"
              transform="translateX(-50%)"
            >
              50
            </Text>
            <Text
              fontSize="xs"
              position="absolute"
              left="83%"
              transform="translateX(-50%)"
            >
              83
            </Text>
            <Text
              fontSize="xs"
              position="absolute"
              left="100%"
              transform="translateX(-50%)"
            >
              100
            </Text>
          </Flex>
          <Progress
            value={sas_score !== "-" ? Number(sas_score) : 0}
            max={100}
            width={`${progressMax}%`}
            bg="gray.100"
            sx={{
              "& > div": {
                backgroundColor: progressColor,
              },
            }}
          />
          {/* 目盛線 */}
          <Box
            position="absolute"
            left="16%"
            top="12px"
            height="12px"
            width="2px"
            bg="gray.400"
          />
          <Box
            position="absolute"
            left="50%"
            top="12px"
            height="12px"
            width="2px"
            bg="gray.400"
          />
          <Box
            position="absolute"
            left="83%"
            top="12px"
            height="12px"
            width="2px"
            bg="gray.400"
          />
          {/* 目盛に対応する重症度 */}
          <Flex position="relative">
            <SeriousnessCategory
              left="8%"
              text="正常"
              category="Healthy"
              decision={decision}
            />
            <SeriousnessCategory
              left="34%"
              text="軽度"
              category="Mild"
              decision={decision}
            />
            <SeriousnessCategory
              left="66.5%"
              text="中等度"
              category="Moderate"
              decision={decision}
            />
            <SeriousnessCategory
              left="91.5%"
              text="重度"
              category="Severe"
              decision={decision}
            />
          </Flex>
        </VStack>
      </Flex>
      <Flex alignItems="flex-end">
        <Text>
          AHI
          <Text as="sup" position="relative" top="-0.3em">
            *2
          </Text>
        </Text>
        <Text mr="10px">{t("Result.SAS_prediction_AHI")}</Text>
        <Text mr="5px">{pred_ahi}</Text>
        <Text fontSize="xs">{t("Result.SAS_prediction_AHI_equate")}</Text>
      </Flex>
    </Box>
  );
}

export function ResultSl1({
  sl1Result,
  analysisType,
}: {
  sl1Result: Sl1Result | null;
  analysisType: AnalysisEngineType | null;
}): ReactElement {
  const { t } = useTranslation();
  const convertSl1Result = ConvertSl1Result({ result: sl1Result });

  function processColumnData(
    phrase: string,
    columnData: Record<string, string>,
    sampleFreq?: string | number
  ): ResultDiagramParam[] {
    const list = Object.values(columnData).map((value) => parseFloat(value));
    const maxValue = Math.max(...list);
    // 合計が100になるように値を丸める
    const roundedList = roundAndAdjust(list);

    const result: ResultDiagramParam[] = [{ value: t(phrase), align: "left" }];

    // サンプリングレートが指定されていたら桁区切りにして追加
    if (sampleFreq !== undefined) {
      result.push({
        value: sampleFreq === "-" ? "-" : Number(sampleFreq).toLocaleString(),
      });
    }

    result.push(
      ...roundedList.map((value, index) => ({
        value,
        // 最もスコアが高い列に背景色を追加
        backgroundColor:
          list[index] === maxValue ? "primary.theme_lv2" : undefined,
      }))
    );

    return result;
  }

  const tableBinarySubScoreColumns: ResultDiagramIndex[] = [
    {
      width: "120px",
      title: t("Result.table_phrase"),
      align: "left",
    },
    {
      width: "100%",
      title: t("Result.SAS_Seriousness_HealthyOrMild"),
      align: "center",
    },
    {
      width: "100%",
      title: t("Result.SAS_SAS_Seriousness_ModerateOrSevere"),
      align: "center",
    },
  ];

  const binaryPhraseA = processColumnData(
    t("PJ.Sl1PhraseA.Phrase"),
    convertSl1Result.ColA_prob1
  );

  const binaryPhrasePA = processColumnData(
    t("PJ.Sl1PhrasePA.Phrase"),
    convertSl1Result.ColPA_prob1
  );

  const tableBinarySubScoreData: ResultDiagramParam[][] = [
    binaryPhraseA,
    binaryPhrasePA,
  ];

  const tableMultiSubScoreColumns: ResultDiagramIndex[] = [
    {
      width: "120px",
      title: t("Result.table_phrase"),
      align: "left",
    },
    {
      width: "80px",
      title: "Hz",
      align: "center",
    },
    {
      width: "100%",
      title: t("Result.SAS_Seriousness_Healthy"),
      align: "center",
    },
    {
      width: "100%",
      title: t("Result.SAS_Seriousness_Mild"),
      align: "center",
    },
    {
      width: "100%",
      title: t("Result.SAS_Seriousness_Moderate"),
      align: "center",
    },
    {
      width: "100%",
      title: t("Result.SAS_Seriousness_Severe"),
      align: "center",
    },
  ];

  const multiColAData = processColumnData(
    "PJ.Sl1PhraseA.Phrase",
    convertSl1Result.ColA_prob2,
    convertSl1Result.ColA_sample_freq
  );
  const multiColPAData = processColumnData(
    "PJ.Sl1PhrasePA.Phrase",
    convertSl1Result.ColPA_prob2,
    convertSl1Result.ColPA_sample_freq
  );
  const multiColGAData = processColumnData(
    "PJ.Sl1PhraseGA.Phrase",
    convertSl1Result.ColGA_prob2,
    convertSl1Result.ColGA_sample_freq
  );
  const multiColRAData = processColumnData(
    "PJ.Sl1PhraseRA.Phrase",
    convertSl1Result.ColRA_prob2,
    convertSl1Result.ColRA_sample_freq
  );

  const tableMultiSubScoreData: ResultDiagramParam[][] = [
    multiColAData,
    multiColPAData,
    multiColGAData,
    multiColRAData,
  ];

  return (
    <Flex flexDirection="column" mb="20px">
      <Flex flexDirection="column" mb="10px">
        <RenderMainResult
          imgSrc={sl1Image}
          content={createElement(RenderMainContent, {
            result: convertSl1Result,
          })}
        />
        <Flex mt="10px" flexDirection="column">
          <Text fontSize="sm">
            <Text as="sup" position="relative" top="-0.3em">
              *1
            </Text>
            {t("Result.SAS_Note1")}
          </Text>
          <Text fontSize="sm">
            <Text as="sup" position="relative" top="-0.3em">
              *2
            </Text>
            {t("Result.SAS_Note2")}
          </Text>
          <Text ml="12px" fontSize="sm">
            {t("Result.SAS_Note2_subtext")}
          </Text>
        </Flex>
      </Flex>
      {analysisType === "sl1" && (
        <Box>
          <Flex flexDirection="column" mt="20px">
            <Text mb="5px">{t("Result.SAS_binary_SubScore")}</Text>
            <RenderUixTable
              diagramIndex={tableBinarySubScoreColumns}
              diagramParams={tableBinarySubScoreData}
            />
          </Flex>
          <Flex flexDirection="column" mt="20px">
            <Text mb="5px">{t("Result.SAS_multi_SubScore")}</Text>
            <RenderUixTable
              diagramIndex={tableMultiSubScoreColumns}
              diagramParams={tableMultiSubScoreData}
            />
          </Flex>
        </Box>
      )}
      {analysisType === "TokioDRSl1" && (
        <Box width="80%">
          <Text fontSize="sm">{t("TokioDR.Sl1.Result.note1")}</Text>
          <Text fontSize="sm" mt={2}>
            {t("TokioDR.Sl1.Result.note2")}
          </Text>
        </Box>
      )}
    </Flex>
  );
}
