import { ReactElement, useContext, useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { Box, Button, Text, useTheme, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { appAxios } from "../../../../utils/appAxios";
import { Layout } from "../../../../components/atoms/Layout";
import {
  useAssertEngineTypeFromPathParam,
  useGetAnalysisEngines,
} from "../../../../utils/selectAnalysisEngine";
import { APP_TYPE } from "../../../../environments";
import {
  ResultUIX,
  UixResultType,
} from "../../../../components/molecules/ResultUIX";
import { isCanGetAverage } from "../../../../engines/baseAnalysisEngine";
import { ApiEngineType, MeanResult } from "../../../../types";
import { employeeInfoAtom } from "../../../../store";
import { useAtom } from "jotai";
import {
  CognitiveResultType,
  ResultCognitive,
} from "../../../../components/molecules/ResultCognitive";
import { ImageContext } from "../../../../components/atoms/ImageProvider";
import TfhGraphSampleImgUrl from "../../../../assets/images/tfh/sampleGraph.png";
import { HeadingWithVerticalLine } from "../../../../components/atoms/HeadingWithVerticalLine";
import { ResultTokioDRCognitive } from "../../../../components/molecules/ResultTokioDRCognitive";

export function Result(): ReactElement {
  const { t } = useTranslation();
  const engineType = useAssertEngineTypeFromPathParam();
  const analysisEngines = useGetAnalysisEngines(engineType);
  const [employeeInfo] = useAtom(employeeInfoAtom);

  const [recordedAt, setRecordedAt] = useState("");
  const [engineResultElements, setEngineResultElements] = useState<
    ReactElement[] | null
  >(null);

  const [title, setTitle] = useState("");

  const { fileId } = useParams();
  useEffect(() => {
    if (!fileId) return;

    if (APP_TYPE === "uix") {
      const resultPromises = analysisEngines.map((engine) =>
        engine.getResult(fileId)
      );

      const averagePromises = analysisEngines.map((engine) => {
        if (isCanGetAverage(engine)) {
          const company_id = employeeInfo.company_id;
          return engine.getAverage(company_id);
        }
        return Promise.resolve(null);
      });

      Promise.all([...resultPromises, ...averagePromises]).then(
        (allResults) => {
          const renderingResults = allResults.slice(0, analysisEngines.length);
          const averageResults = allResults.slice(analysisEngines.length);

          const resultsMap: Map<ApiEngineType, UixResultType> = new Map();
          for (let i = 0; i < analysisEngines.length; i++) {
            const engineType = analysisEngines[i]._engineType;
            resultsMap.set(engineType, renderingResults[i] as UixResultType);
          }

          const averageMap: Map<ApiEngineType, MeanResult> = new Map();
          for (let i = 0; i < analysisEngines.length; i++) {
            if ("getAverage" in analysisEngines[i]) {
              const engineType = analysisEngines[i]._engineType;
              averageMap.set(engineType, averageResults[i] as MeanResult);
            }
          }

          setEngineResultElements([
            <ResultUIX
              key=""
              resultsMap={resultsMap}
              averageMap={averageMap}
            />,
          ]);
        }
      );
    } else if (
      engineType === "Cognitive" ||
      engineType === "TokioDRCognitive"
    ) {
      const resultPromises = analysisEngines.map((engine) =>
        engine.getResult(fileId)
      );
      Promise.all(resultPromises).then((allResults) => {
        const renderingResults = allResults.slice(0, analysisEngines.length);
        const resultsMap: Map<ApiEngineType, CognitiveResultType> = new Map();
        for (let i = 0; i < analysisEngines.length; i++) {
          const engineType = analysisEngines[i]._engineType;
          resultsMap.set(
            engineType,
            renderingResults[i] as CognitiveResultType
          );
        }
        if (engineType === "Cognitive") {
          setEngineResultElements([
            <ResultCognitive key="" resultsMap={resultsMap} />,
          ]);
        } else {
          setEngineResultElements([
            <ResultTokioDRCognitive key="" resultsMap={resultsMap} />,
          ]);
        }
      });
    } else {
      const renderingPromises = analysisEngines.map((engine, engineIndex) =>
        engine.renderResult(engineIndex, fileId, t)
      );

      Promise.all(renderingPromises).then((renderingResults) => {
        setEngineResultElements(renderingResults);
      });
    }
  }, [fileId, t, analysisEngines, employeeInfo.company_id, engineType]);

  useEffect(() => {
    appAxios
      .get(`/voice`, { params: { file_id: fileId } })
      .then((voiceResponse) => {
        //現状ではrecorded_atは日本時間しかないのでtoLocaleString("ja")にしているが、
        //サーバ側でvoiceResponseでlocaleが指定されれば、それに応じた時刻表示を行う。
        const recorded_at = new Date(voiceResponse.data.recorded_at);
        setRecordedAt(recorded_at.toLocaleString("ja"));
      })
      .catch(() => setRecordedAt(t("Result.Error.RecordedAt")));
  }, [fileId, t]);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  useEffect(() => {
    if (engineType === "Co1Demo") {
      setTitle("(" + t("Co1Demo.Title") + ")");
    } else if (engineType === "Mi2Demo") {
      setTitle("(" + t("Mi2Demo.Title") + ")");
    }
  }, [engineType, t]);

  const { imageSrc, preloadImage } = useContext(ImageContext);

  useEffect(() => {
    if (
      engineType === "TFH" &&
      imageSrc &&
      !imageSrc.TfhImg["GraphSampleImg"]
    ) {
      preloadImage &&
        preloadImage("TfhImg", {
          GraphSampleImg: TfhGraphSampleImgUrl,
        });
    }
  }, [engineType, imageSrc, preloadImage]);

  if (!fileId) {
    return <Navigate to="/404" replace />;
  } else if (engineResultElements !== null) {
    return (
      <Layout>
        {title === "" && engineType !== "TFH" && (
          <Layout.Title
            color="#555555"
            subTitle={
              <Text
                as="small"
                fontWeight="bold"
                fontSize="14px"
                paddingY="6px"
                color="text.main_text_lv1"
              >
                {recordedAt}
              </Text>
            }
          >
            {t("Result.analysis_results")}
          </Layout.Title>
        )}
        {title !== "" && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Text fontSize="2xl">{t("Result.analysis_results")}</Text>
            <Text fontSize="lg">{title}</Text>
            <Text
              as="small"
              fontWeight="bold"
              fontSize="14px"
              paddingY="6px"
              color="text.main_text_lv1"
            >
              {recordedAt}
            </Text>
          </Box>
        )}
        {engineResultElements}
        {engineType === "TFH" && (
          <Box
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
            mb={5}
          >
            {/* サンプル測定推移画像 */}
            <HeadingWithVerticalLine
              title={t("TFH.MeasurementResultTransition")}
              description={t("TFH.MeasurementResultTransition.Title")}
            />
            <Image
              src={imageSrc ? imageSrc.TfhImg["GraphSampleImg"] : undefined}
              boxSize="100%"
              mb={8}
            />
            <Text mb={4} fontSize="sm">
              {t("TFH.Result.note1")}
            </Text>
            <Text mb={4} fontSize="sm">
              {t("TFH.Result.note2")}
            </Text>
            <Text mb={4} fontSize="sm">
              {t("TFH.Result.note3")}
            </Text>
          </Box>
        )}
        <Button
          as={Link}
          to="../../../../home"
          size="lg"
          variant="btn_primary"
          width="full"
        >
          HOME
        </Button>
      </Layout>
    );
  } else {
    return <></>;
  }
}
