import React, { ReactElement } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  BoxProps,
  HTMLChakraProps,
  Stack,
  Box,
  Text,
} from "@chakra-ui/react";
import {
  AnalysisResult as CogResult,
  ConvertResult as ConvertCogResult,
} from "../../engines/cogEngine";
import {
  AnalysisResult as Co1Result,
  ConvertResult as ConvertCo1Result,
} from "../../engines/co1Engine";
import { CognitiveResultType } from "./ResultCognitive";
import { useTranslation } from "react-i18next";

type ThProps = HTMLChakraProps<"th"> & BoxProps;
type TdProps = HTMLChakraProps<"td"> & BoxProps;

const CustomTh: React.FC<ThProps> = ({ children, ...props }) => {
  return (
    <Th
      border="1px solid black"
      textAlign="center"
      textTransform="none"
      lineHeight="10px"
      verticalAlign="bottom"
      fontSize="lg"
      height="30px"
      {...props}
    >
      {children}
    </Th>
  );
};

const CustomTd: React.FC<TdProps> = ({ children, ...props }) => {
  return (
    <Td
      border="1px solid black"
      textAlign="center"
      lineHeight="1px"
      height="40px"
      bg="white"
      {...props}
    >
      {children}
    </Td>
  );
};

export function ResultTokioDRCognitive({
  resultsMap,
}: {
  resultsMap: Map<string, CognitiveResultType>;
}): ReactElement {
  const { t } = useTranslation();
  const cogResult = resultsMap.get("cog") as CogResult;
  const co1Result = resultsMap.get("co1") as Co1Result;
  const converCogResult = ConvertCogResult({ result: cogResult });
  const converCo1Result = ConvertCo1Result({ result: co1Result });

  return (
    <Stack spacing={4} mb={4}>
      <Table style={{ tableLayout: "fixed" }} bg="white">
        <Thead bg="gray.200" height="40px" borderColor="black">
          <Tr>
            <CustomTh>Engine</CustomTh>
            <CustomTh>認知機能スコア</CustomTh>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <CustomTd>Cog</CustomTd>
            <CustomTd>{converCogResult.cognitive_score}</CustomTd>
          </Tr>
          <Tr>
            <CustomTd>Co-1</CustomTd>
            <CustomTd>{converCo1Result.Co1_score}</CustomTd>
          </Tr>
        </Tbody>
      </Table>
      <Box width="80%">
        <Text fontSize="sm">{t("Co1DemoResult.note1")}</Text>
        <Text fontSize="sm" mt={2}>
          {t("Co1DemoResult.note2")}
        </Text>
      </Box>
    </Stack>
  );
}
