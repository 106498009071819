import { BaseAnalysisEngine } from "../engines/baseAnalysisEngine";
import { AnalysisEngineType, isValidAnalysisEngineType } from "../types";
import { useAtom } from "jotai";
import {
  cogEnginesAtom,
  co1EnginesAtom,
  dysphagiaEnginesAtom,
  mi1_Mi2EnginesAtom,
  mi1EnginesAtom,
  np1EnginesAtom,
  cognitiveEnginesAtom,
  mi2EnginesAtom,
  br1EnginesAtom,
  uixEnginesAtom,
  co1DemoEnginesAtom,
  sl1EnginesAtom,
  Mi2DemoEnginesAtom,
  Co2DemoEnginesAtom,
  tfhEnginesAtom,
  tokioDRCognitiveEnginesAtom,
  tokioDRSl1EngineAtom,
} from "../store";
import { useParams } from "react-router-dom";

export function useAssertEngineTypeFromPathParam(): AnalysisEngineType {
  const { engineType } = useParams();
  if (!isValidAnalysisEngineType(engineType))
    throw new Error("Internal Error! Invalid Engine Type");
  return engineType;
}

export function useGetAnalysisEngines(
  engineType: AnalysisEngineType
): BaseAnalysisEngine<unknown>[] {
  const [miEngines] = useAtom(mi1_Mi2EnginesAtom);
  const [cognitiveEngines] = useAtom(cognitiveEnginesAtom);
  const [cogEngines] = useAtom(cogEnginesAtom);
  const [co1Engines] = useAtom(co1EnginesAtom);
  const [np1Engines] = useAtom(np1EnginesAtom);
  const [dysphagiaEngines] = useAtom(dysphagiaEnginesAtom);
  const [mi1Engines] = useAtom(mi1EnginesAtom);
  const [mi2Engines] = useAtom(mi2EnginesAtom);
  const [br1Engines] = useAtom(br1EnginesAtom);
  const [uixEngines] = useAtom(uixEnginesAtom);
  const [co1DemoEngines] = useAtom(co1DemoEnginesAtom);
  const [sl1Engines] = useAtom(sl1EnginesAtom);
  const [Mi2DemoEngines] = useAtom(Mi2DemoEnginesAtom);
  const [Co2DemoEngines] = useAtom(Co2DemoEnginesAtom);
  const [tfhEngines] = useAtom(tfhEnginesAtom);
  const [tokioDRSl1Engines] = useAtom(tokioDRSl1EngineAtom);
  const [tokioDRCognitiveEngines] = useAtom(tokioDRCognitiveEnginesAtom);

  switch (engineType) {
    case "Mi1_Mi2":
      return miEngines;
    case "Cognitive":
      return cognitiveEngines;
    case "cog":
      return cogEngines;
    case "co1":
      return co1Engines;
    case "np1":
      return np1Engines;
    case "dysphagia":
      return dysphagiaEngines;
    case "mi2":
      return mi2Engines;
    case "br1":
      return br1Engines;
    case "UIX":
      return uixEngines;
    case "Co1Demo":
      return co1DemoEngines;
    case "NTT":
      return mi1Engines;
    case "sl1":
      return sl1Engines;
    case "Mi2Demo":
      return Mi2DemoEngines;
    case "Co2Demo":
      return Co2DemoEngines;
    case "TFH":
      return tfhEngines;
    case "TokioDRSl1":
      return tokioDRSl1Engines;
    case "TokioDRCognitive":
      return tokioDRCognitiveEngines;
  }
}
